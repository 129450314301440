export const facts = [
"The population of Madison was 269,840 as of the 2020 census, making it the second-most populous city in Wisconsin, after Milwaukee, and the 77th-most populous in the United States.",
"The Madison metropolitan area has a population of 680,796 as of the 2020 census.",
"The city is located on an isthmus and lands surrounding five lakes—Lake Mendota, Lake Monona, Lake Wingra, Lake Kegonsa and Lake Waubesa.",
"Madison was founded in 1836 and is named after American Founding Father and President James Madison.",
"Madison is home to an extensive network of parks, the most parks and playgrounds per capita of any of the 100 largest U.S. cities, and is considered a bicycle-friendly community.",
"Madison is home to nine National Historic Landmarks, including several buildings designed by architect Frank Lloyd Wright, such as the UNESCO World Heritage Site Jacobs I House.",
"Residents of Madison are known as Madisonians.",
"As of 2021, Madison was the fastest-growing city in Wisconsin.",
"Madison's economy features a large and growing technology sector, and the Madison area is home to the headquarters of Epic Systems, American Family Insurance, Exact Sciences, Promega, American Girl, Sub-Zero, Lands' End, Spectrum Brands, a regional office for Google, and the University Research Park, as well as many biotechnology and health systems startups.",
"Before Europeans, people inhabited the area in and around Madison for about 12,000 years. The Ho-Chunk call the region Teejop meaning 'land of the four lakes' (Mendota, Monona, Waubesa, and Kegonsa).",
"Camp Randall, on the west side of Madison, was built and used as a training camp, a military hospital, and a prison camp for captured Confederate soldiers. After the war ended, the Camp Randall site was absorbed into the University of Wisconsin and Camp Randall Stadium was built there in 1917.",
"48.2% of Madison's population over the age of 25 holds at least a bachelor's degree.",
"The University of Wisconsin–Madison, University of Wisconsin Hospital and Clinics, and Wisconsin state government remain the largest employers in the city, while Epic Systems is the largest private sector employer.",
"The Onion satirical newspaper, as well as the pizza chains Rocky Rococo and the Glass Nickel Pizza Company, originated in Madison.",
"Madison is home to a large biotech and health information technology scene. Notable companies headquartered in Madison in this field include Epic Systems, Panvera (now part of Invitrogen), Exact Sciences, and Promega. Arrowhead Pharmaceuticals, Thermo Fischer Scientific, pipette manufacturer Gilson, Catalent, and Covance have large offices in the city.",
"Epic Systems was based in Madison from 1979 to 2005, when it moved to a larger campus in the nearby Madison suburb of Verona.",
"The Madison metropolitan area is home to the headquarters or manufacturing of three notable bicycle brands: Trek, Mongoose, and Pacific Cycle.",
"Supported by naturally fertile soil, Madison's infrastructure supports food production, processing, and distribution. Major employers include Hormel Foods, Del Monte, and Frito-Lay.",
"The meat producer Oscar Mayer was a Madison fixture for decades, and was a family business for many years before being sold to Kraft Foods. Its Madison headquarters and manufacturing facility were shuttered in 2017.",
"The Memorial Union is a central gathering place on Lake Mendota. Memorial Union Terrace is home to uniquely designed 'terrace chairs' with a sunburst design that have become a symbol of the city.",
"Henry Vilas Zoo is a 28-acre public zoo owned by Dane County which receives over 750,000 visitors annually. It is one of ten remaining free zoos in North America.",
"Olbrich Botanical Gardens contains a 16-acre outdoor botanical garden and 10,000-square-foot conservatory.",
"Madison's architectural landmarks reflect a wide range of styles, ranging from the first Usonian house designed by modern architect Frank Lloyd Wright to imposing brutalist buildings on the campus of UW–Madison and art deco towers interspersed through the downtown.",
"The height of Madison's skyline is limited by a state law that restricts building heights in the downtown area. All buildings within one mile (1.6 km) of the Wisconsin State Capitol have to be less than 1,032.8 feet (314.8 m) above sea level to preserve the view of the building from most areas of the city.",
"Madison is home to eight buildings designed by influential Wisconsin-born modern architect Frank Lloyd Wright, more than any city outside of the Chicago metropolitan area. His designs in Madison include the city's lakefront convention center, Monona Terrace, as well as Wright's first Usonian house, the Herbert and Katherine Jacobs First House, which is a UNESCO World Heritage Site.",
"Madison, and especially the UW–Madison campus, have numerous buildings in the brutalist style. These structures include the George L. Mosse Humanities Building designed by Harry Weese and the Chazen Museum of Art.",
"The Orpheum Theater is located on State Street one block from the capital. This Art Deco building was added to the National Register of Historic Places as Madison's best surviving representative of the movie palace era.",
"The land surrounding Madison is home to numerous dairy farms, which leads to a dairy heavy cuisine. The Combined Statistical Area host numerous cheesemakers, including the award-winning Uplands Cheese, Hooks Cheese Company, and Landmark Creamery.",
"Restaurants in Madison often feature cheese curds served either fried with dipping sauce, such as ranch dressing or 'squeaky' (not cooked, so called because of the squeaking sound they often make against the teeth when chewed), usually served without dipping sauce.",
"Another popular food in Madison is hot and spicy cheese bread, made by some Madison bakeries and available at farmer's markets around the city.",
"On Saturday mornings in the summer, the Dane County Farmers' Market is held around Capitol Square, the largest producer-only farmers' market in the country. This market attracts numerous vendors who sell fresh produce, meat, cheese, and other products.",
"Morning Buns, a variety of sticky bun made with croissant dough, were invented in Madison at the late restaurant, the Ovens of Brittany.",
"Madison is home to numerous Wisconsin-style supper clubs. An all-you-can-eat Friday night fish fry is particularly common at Wisconsin supper clubs, as are old fashioned cocktails.",
"German immigrants to Madison in the late 19th and early 20th century brought with them a strong culinary tradition. Multiple restaurants in Madison are modeled after German-style beer halls. Some restaurants participate in twice-monthly (from June to October, once a month otherwise) Bavarian smorgasbord.",
"Madison is home to a large Hmong population, leading to a variety of Laotian and Hmong restaurants that make the city a 'national hub of Hmong cuisine'. The city is home to unique foods such as the large spring rolls sold from the food carts on Capital Square and State Street, particularly in warmer months.",
"Madison is home to the World's Largest Brat Fest which sells over 200,000 bratwurst sausages annually during Memorial Day weekend.",
"Several venues in Madison offer live music nightly, spreading from the historic Barrymore Theatre and High Noon Saloon on the east side to small coffee houses and wine bars. The biggest headliners sometimes perform at the Orpheum Theatre, the Overture Center, Breese Stevens Field, the Alliant Energy Center, or the UW Theatre on campus.",
"Madison was home to Smart Studios, Butch Vig and Steve Marker's longtime studio where many notable alternative rock records of the 1990s and 2000s were recorded and/or produced, including one by Nirvana.",
"The band Garbage formed in Madison in 1994.",
"The late comedian and actor Chris Farley was born and raised in Madison.",
"The Madison Opera, the Madison Symphony Orchestra, Forward Theater Company, the Wisconsin Chamber Orchestra, and the Madison Ballet are some of the professional resident companies of the Overture Center for the Arts.",
"There are several cooperative organizations in the Madison area, ranging from grocery stores (such as the Willy Street Cooperative) to housing co-ops (such as Madison Community Cooperative and Nottingham Housing Cooperative) to worker cooperatives (including an engineering firm, a wholesale organic bakery, and a cab company).",
"In 2009, the Madison Common Council voted to name the plastic pink flamingo as the official city bird.",
"On May 17, 2018, it was announced that Forward Madison FC would become Madison's first professional soccer team, and are members of USL League One. They play their home matches at the historic Breese Stevens Field.",
"Madison is home to the Madison Mallards, a college wood-bat summer baseball league team in the Northwoods League. They play in Warner Park on the city's north side from June to August.",
"Madison has several active ultimate disc leagues organized through the nonprofit Madison Ultimate Frisbee Association. In 2013, the Madison Radicals, a professional ultimate frisbee team, debuted in the city.",
"Madison is home to several endurance sports racing events, such as the Crazylegs Classic, the CrossFit Games, Paddle and Portage, the Mad City Marathon, and Ironman Wisconsin, which attracts over 45,000 spectators.",
"The Wisconsin Rugby Club, the 1998 and 2013 USA Rugby Division II National Champions, and the Wisconsin Women's Rugby Football Club are the state's only Division I women's rugby team.",
"As of 2017, the CrossFit Games have been held at Madison’s Alliant Energy Center.",
"The University of Wisconsin–Madison Arboretum manages 520 acres of remnant forests and prairies throughout Wisconsin. The 300-acre (1.2 km2) Lakeshore Nature Preserve preserves native species along the southern shore of Lake Mendota.",
"During the winter months, sports enthusiasts enjoy ice boating, ice skating, ice hockey, ice fishing, cross-country skiing, and snowkiting. During the rest of the year, outdoor recreation includes sailing on the local lakes, bicycling, and hiking.",
"Madison is known for its extensive biking infrastructure, with numerous bike paths and bike lanes throughout the city. Several of these bike paths connect to state trails, such as the Capital City State Trail, Military Ridge State Trail, and Badger State Trail. In 2015 Madison was awarded platinum level Bicycle Friendly Community designation from the League of American Bicyclists, one of only five cities in the US to receive this (highest) level.",
"Madison has a mayor-council system of government. Madison's city council, known as the Common Council, consists of 20 members, one from each district. The mayor is elected in a citywide vote.",
"The Onion, a satirical weekly, was founded in Madison in 1988 and published from there until it moved to New York in 2001.",
"Madison is associated with 'Fighting Bob' La Follette and the Progressive movement. La Follette's magazine, The Progressive, founded in 1909, is still published in Madison.",
"Madison hosts two volunteer-operated and community-oriented radio stations, WORT and WSUM. WORT Community Radio (89.9 FM), founded in 1975, is one of the oldest volunteer-powered radio stations in the United States.",
"Madison’s nicknames include Mad City, Madtown, Four Lakes City, and the People's Republic of Madison.",
"Madison’s sister cities include Arcatao, El Salvador; Bahir Dar, Ethiopia; Camagüey, Cuba; Freiburg im Breisgau, Germany; and Kanifing, Gambia."
];